.RootResults {
  padding: $sectionPaddingSpace 0;

  .rowSection {
    max-width: 90%;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    padding: $sectionPaddingSpaceMobile 0;
  }
}

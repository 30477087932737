.cardStyle {
  background: theme-color('cards');
  padding: 3vw;
  .cardHeader,
  .cardBody {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    @media (max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .cardBody {
    h4 {
      font-family: 'Akkordeon fourteen';
      font-style: normal;
      font-weight: 900;
      font-size: 3.5vw;
      line-height: 3vw;

      @media (max-width: 991px) {
        font-size: 10.5vw;
        line-height: 10vw;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 6vw;
  }
}

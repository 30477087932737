.rowSection {
  .forMeRow {
    .forMeCol {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5vw;
      .forMeDivWrap {
        width: 100%;

        position: relative;
        top: -10vw;

        h3 {
          width: max-content;
        }
      }
      @media (max-width: 991px) {
        // padding: 10vw;

        .forMeDivWrap {
          top: -20vw;
          h3 {
            width: auto;
          }
        }
      }
    }
  }
}

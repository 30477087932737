.RootFooter {
  // background-color: theme-color("primary");
  height: auto;
  // padding: 250px 50px 50px 50px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  position: relative;

  padding-top: 5vw;
  padding-bottom: 3vw;
  @media (max-width: 991px) {
    margin-left: 0 !important;
  }

  .footerBgImg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    @media (max-width: 991px) {
      object-fit: cover;
      object-position: right;
    }
  }

  .footerCol {
    display: flex;
    justify-content: center;
    align-items: center;

    .listGroupFooter {
      width: fit-content;
      // @media (max-width: 991px) {
      //   width: 60%;
      // }
    }

    .footerColWrapper {
      // width: 100%;
      .footerColDiv {
        display: flex;
        align-items: center;
        // justify-content: center;

        &:nth-child(1) {
          padding-left: 3em;
        }
        @media (max-width: 991px) {
          justify-content: center;
          &:nth-child(1) {
            padding-left: 0;
          }
        }
        // &:nth-child(2) {
        //   justify-content: flex-start;
        // }

        .footerSubTitle .textStroke {
          -webkit-text-stroke: 1px theme-color('primary');
          // color: theme-color('primary');
        }
      }
    }
    .footerFaceText {
      margin-top: 3vw;
      color: theme-color('textColor');
      font-size: 13px;
      line-height: 18px;
      @media (max-width: 1500px) and (min-width: 992px) {
        font-size: 1vw;
        line-height: 1.5vw;
      }
      @media (max-width: 991px) {
        margin-top: 15vw;

        font-size: 3vw;
        line-height: 3.5vw;
      }
    }
    @media (max-width: 991px) {
      text-align: center;
    }
  }
}

.listGroupItemFooter a {
  text-decoration: none;
  color: theme-color('secondary');
  transition: 0.3s;

  &:hover {
    color: theme-color('textColor');
  }
}

.brandFooter {
  color: theme-color('secondary');
  align-items: flex-start !important;
}

.listGroupItemFooter {
  background-color: transparent;
  border: none;
  padding: 0;
  padding-bottom: 0.3em;
  margin-bottom: 0.3em;
}

.listGroupTitle {
  padding: 0;
  //  padding-bottom: 1em;

  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 1vw;
  @media (max-width: 991px) {
    justify-content: center;
    margin-bottom: 4vw;
  }
}

.poweredSection {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.powered {
  margin-top: 2vw;
  color: theme-color('darkColor');

  a {
    color: theme-color('primary');
  }
  @media (max-width: 991px) {
    margin-top: 8vw;
  }
}

.powered a {
  color: theme-color('primary2');
  transition: color 0.3s;
  &:hover {
    color: theme-color('titleColor');
    text-decoration: none;
  }
}
.listGroupItemFooter a {
  cursor: pointer;
}

@media (max-width: 991px) {
  .RootFooter {
    padding-top: 10vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 10vw;
  }

  .socialButtons button {
    width: 50px;
    height: 50px;
  }

  .socialButtonsToolbar .btn {
    padding: 0;
  }

  .copyText:nth-child(1) {
    margin-bottom: 10px;
  }

  .brandFooter {
    margin-bottom: 50px;
  }

  .interestLinks {
    margin-bottom: 30px;
  }
}

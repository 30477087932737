// ACCORDION
.rowSection {
  .faqColTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    .faqDivTitle {
      text-align: center;
    }
    h2 {
      margin-bottom: 0;
    }
    img {
      margin-left: 3vw;
      width: 10vw;
      max-width: 150px;
    }
    @media (max-width: 991px) {
      justify-content: center;
    }
  }
  .accordionFaq {
    .accordionCardSpace {
      background-color: transparent;

      margin: 3em 0;
      @media (max-width: 991px) {
        margin: 0;
      }
    }
  }
  .accordionCardTitle {
    background: transparent !important;
    &.accordionCardTitle2 {
      margin-top: 3em;
    }
    * {
      background: transparent !important;
    }
  }
  .accordionCard {
    position: relative;
    border: none;
    border-bottom: 1px solid theme-color('textColor') !important;
    border-radius: 0;

    width: 100%;

    margin-left: 6vw !important;

    &.accordionCardFaq {
      .accordionToggle {
        background: transparent;
        border: none;
        padding: 3em 2em 2vw 0;
        cursor: pointer;
        .accordionToggleDiv {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .accordionToggleIcon {
            position: absolute;
            left: -6vw;

            // padding: 2vw;
            background-color: theme-color('transparent');
            border: 1px solid theme-color('secondary');
            border-radius: 50%;
            max-width: 80px;
            max-height: 80px;
            width: 5vw;
            height: 5vw;

            display: flex;
            justify-content: center;
            align-items: center;

            // svg,
            // path {
            //   background: transparent !important;
            //   fill: theme-color('secondary');
            // }

            @media (max-width: 991px) {
              width: 10vw;
              height: 10vw;

              left: -13vw;

              img {
                width: 4vw;
              }
            }
          }
          h4 {
            margin-bottom: 0;
          }
        }
      }
    }
    .accordionBody {
      padding: 0 2em 2em 0;
      .accordionText {
        span {
          color: theme-color('primary');
        }
        a {
          color: theme-color('darkColor');
        }

        p {
          opacity: 0.8;
        }
      }
      @media (max-width: 991px) {
        padding: 0 1vw 1vw 0;
      }
    }

    @media (max-width: 991px) {
      margin-left: 11vw !important;
    }
  }
}

.navContent {
  margin-right: 10%;
  transition: 0.3s;
}

#navbar {
  transform: translateY(0);
  &.hideNavbar {
    transform: translateY(-100%);
  }
}

// on focus
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: inherit;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: inherit;
}
//
.navbarBrand {
  color: #fff !important;

  margin-right: 0;
}

// .navbar-light .navbar-nav .nav-link {
//   // color: theme-color('textColor') !important;
// }

.fixed-top {
  top: 0;
  height: 72px;
}

#navbar {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease-in-out;
}

.iniciarSesion {
  font-family: 'Akkordeon ten';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;

  @media (max-width: 1600px) {
    font-size: 2vw;
    line-height: 2.5vw;
  }
  @media (max-width: 991px) {
    font-size: 5vw;
    line-height: 5.5vw;
  }

  color: theme-color('titleColor') !important;

  &:hover {
    color: theme-color('primary2') !important;
  }
}

.navCollapse {
  display: flex;
  justify-content: space-between;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.3s;
}

.navbar-light .navbar-nav .nav-link {
}

.navbar-light .navbar-nav .nav-link:hover {
  color: theme-color('primary') !important;
}

.nav-color-top {
  background-color: transparent;
  transition: 1s;
}

.nav-color-scroll {
  background-color: rgba(14, 14, 14, 0);
  padding-right: 5vw;
  padding-left: 5vw;

  @media (max-width: 991px) {
    // padding-right: 10vw;
    // padding-left: 10vw;
  }
}

#navbar {
  // transition: top 0.3s, 0.5s;
}

.navbar--hidden {
  top: -50px;
}

.navbar-collapse {
  // position: absolute;
  right: 86px;
}

//TOGGLER
.navbar-toggler {
  border: none;
  font-size: 2em;
}

/* RESPONSIVE STYLE */

@media (max-width: 991px) {
  #navbar {
    height: 72px;
    // padding-left: 0;
    // padding-right: 0;
  }
  .navbar-brand {
    img {
      width: auto;
      height: 100%;
      @media (max-width: 991px) {
        width: 40vw;
      }
    }
  }

  #containerNavToggle {
    height: 100% !important;
  }

  .nav-color {
    background: rgba(0, 0, 0, 0.747);
  }

  // .fixed-top {
  //    height: 70px;
  // }

  .navbar-light .navbar-nav .nav-link {
    text-align: center;
  }

  .nav-link {
    padding: 3vw;
  }

  .nav-link:hover {
    color: theme-color('primary');
  }

  .navbar-light .navbar-nav .nav-link {
    margin-right: 0;
  }

  .navbar-collapse {
    position: initial;
    right: 0;
  }

  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 0.8)' stroke-width='4' stroke-linecap='butt' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .navbar-toggler {
    margin-right: 20px;
  }

  .navbar-collapse {
    background-color: #fff;
  }
}

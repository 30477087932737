$RootRegisterPadding: $sectionPaddingSpace - 2vw;
$RootRegisterPaddingMobile: $sectionPaddingSpaceMobile - 2vw;
.RootRegister {
  padding: $RootRegisterPadding 0 0 0;

  .rowSection {
    position: relative;
    overflow: hidden;
    padding: 5vw 0;
    @media (max-width: 991px) {
      padding: 10vw 0;
    }
  }
  @media (max-width: 991px) {
    padding: $RootRegisterPaddingMobile 0 0 0;
  }
}

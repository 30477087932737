.rowSection {
  .homeRow {
    .homeCol {
      .jumboHome {
        p.pOpac {
          margin: 1em 0;
          width: 60%;
          span {
            margin-top: 0.5em;
          }
          @media (max-width: 1300px) {
            width: 80%;
          }
        }

        .buttonWrapper {
          &:nth-child(1) {
            margin-right: 1vw;
          }
          &:nth-last-child(1) {
            margin-left: 1vw;
          }
        }
        @media (max-width: 991px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .homePriceContent {
            margin-right: 20vw;

            .h4HeroPrice {
              &:nth-child(3) {
                opacity: 0.5;
              }
              &:nth-child(4) {
                opacity: 0.2;
              }
            }
          }
          p.pOpac {
            text-align: center;
            margin: 5vw 0 8vw 0;
            width: 100%;
          }

          .buttonsGroupWrap {
            .buttonWrapper {
              margin-left: 0;
              margin-right: 0;
              width: 80%;
              .buttonStyle {
                width: 100%;
              }
            }
            .buttonWrapperSecondary {
              margin-top: 5vw;
            }
          }
        }
        .playDivMobile {
          @media (max-width: 991px) {
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
          }
        }
      }
    }
    .homeColVsl {
      display: flex;
      justify-content: center;
      align-items: center;
      .homePriceContent {
        margin-top: 5vw;

        p {
          margin-top: 1vw;
        }
      }
    }
  }
}

.RootTips {
  z-index: 2;
  padding: 0 0 $sectionPaddingSpace 0;

  .rowSection {
    z-index: 2;
    max-width: calc(70%);
    position: relative;
    margin-top: -10vw;
    @media (max-width: 991px) {
      max-width: calc(80%);
      margin-top: -45vw;
    }
  }
  @media (max-width: 991px) {
    padding: $sectionPaddingSpaceMobile 0;
  }
}

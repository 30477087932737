.RootFaq {
  width: 100%;

  padding: $sectionPaddingSpace 0;

  position: relative;

  justify-content: center;

  .rowSection {
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  @media (max-width: 991px) {
    padding: $sectionPaddingSpaceMobile 0;
  }
}

.mainContainer {
  overflow-x: hidden;
}

.sectionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowSection {
  width: 100%;
  max-width: 80%;

  @media (max-width: 991px) {
    max-width: 90%;
  }
}

.imgsCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rowSection {
  .sliderRow {
    .sliderCol {
      .item {
        padding: 0 2em;
      }
      .sliderImg {
        position: relative;
        width: 100%;
        background: theme-color('cards');

        &::after {
          content: '';
          display: block;
          padding-bottom: 100%;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          filter: brightness(80%);
        }
      }

      &.sliderColTitle {
        // position: absolute;
        z-index: 1;
        h3 {
          width: max-content;
        }
        .sliderDivTitle {
          position: relative;
          // left: 5vw;
          h3 {
            pointer-events: none;
          }
          p {
            margin: 3vw 5vw 3vw 0;
          }
          .sliderDivTitleWrap {
            display: flex;
            align-items: center;

            // @media (max-width: 991px) {
            //   img {
            //     transform: rotate(90deg);
            //   }
            // }
          }
        }
      }
      &.sliderColSlick {
        width: 100%;
        // padding-left: 30vw;
      }

      &.sliderColBtn {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 3vw;
        @media (max-width: 991px) {
          margin-top: 6vw;
        }
      }
    }
  }
}

.RootThankHome {
  @extend .RootHome;

  .rowSection {
    max-width: 90%;
  }

  .homeCol {
    .jumboHome {
      p.pOpac {
        width: 80% !important;
        @media (max-width: 991px) {
          width: 100% !important;
        }
      }
    }
  }

  .homeColVsl {
    & > div {
      position: relative;
      width: 100%;
    }

    @media (max-width: 991px) {
      margin-top: 10vw;
    }
  }

  .embedContainer {
  }
}

.RootForMe {
  padding: $sectionPaddingSpace 0 0 0;
  z-index: 1;
  .rowSection {
    position: relative;
    z-index: 2;
    background: theme-color('cards');
    max-width: 100%;
    padding: 0 10vw;
    .forMeImg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      filter: brightness(40%);
    }
    @media (max-width: 991px) {
      padding: 0 5vw;
    }
  }
  @media (max-width: 991px) {
    padding: $sectionPaddingSpaceMobile 0;
  }
}

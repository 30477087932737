.RootPlans {
  padding: 0 0 $sectionPaddingSpace 0;

  .rowSection {
    position: relative;
    overflow: hidden;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  @media (max-width: 991px) {
    padding: 0 0 $sectionPaddingSpaceMobile 0;
  }
}

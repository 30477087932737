@font-face {
  font-family: 'Akkordeon five';

  src: url('./five/Emtype\ Foundry\ -\ Akkordeon\ Five.eot'); /* IE9*/
  src: url('./five/Emtype\ Foundry\ -\ Akkordeon\ Five.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./five/Emtype\ Foundry\ -\ Akkordeon\ Five.woff2')
      format('woff2'),
    /* chrome、firefox */ url('./five/Emtype\ Foundry\ -\ Akkordeon\ Five.woff')
      format('woff'),
    /* chrome、firefox */ url('./five/Emtype\ Foundry\ -\ Akkordeon\ Five.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./five/Emtype\ Foundry\ -\ Akkordeon\ Five.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Akkordeon fourteen';

  src: url('./fourteen/Emtype\ Foundry\ -\ Akkordeon\ Fourteen.eot'); /* IE9*/
  src: url('./fourteen/Emtype\ Foundry\ -\ Akkordeon\ Fourteen.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./fourteen/Emtype\ Foundry\ -\ Akkordeon\ Fourteen.woff2')
      format('woff2'),
    /* chrome、firefox */
      url('./fourteen/Emtype\ Foundry\ -\ Akkordeon\ Fourteen.woff')
      format('woff'),
    /* chrome、firefox */
      url('./fourteen/Emtype\ Foundry\ -\ Akkordeon\ Fourteen.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./fourteen/Emtype\ Foundry\ -\ Akkordeon\ Fourteen.otf')
      format('opentype');

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Akkordeon nine';

  src: url('./nine/Emtype\ Foundry\ -\ Akkordeon\ Nine.eot'); /* IE9*/
  src: url('./nine/Emtype\ Foundry\ -\ Akkordeon\ Nine.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./nine/Emtype\ Foundry\ -\ Akkordeon\ Nine.woff2')
      format('woff2'),
    /* chrome、firefox */ url('./nine/Emtype\ Foundry\ -\ Akkordeon\ Nine.woff')
      format('woff'),
    /* chrome、firefox */ url('./nine/Emtype\ Foundry\ -\ Akkordeon\ Nine.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./nine/Emtype\ Foundry\ -\ Akkordeon\ Nine.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Akkordeon seven';

  src: url('./seven/Emtype\ Foundry\ -\ Akkordeon\ Seven.eot'); /* IE9*/
  src: url('./seven/Emtype\ Foundry\ -\ Akkordeon\ Seven.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./seven/Emtype\ Foundry\ -\ Akkordeon\ Seven.woff2')
      format('woff2'),
    /* chrome、firefox */
      url('./seven/Emtype\ Foundry\ -\ Akkordeon\ Seven.woff') format('woff'),
    /* chrome、firefox */
      url('./seven/Emtype\ Foundry\ -\ Akkordeon\ Seven.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./seven/Emtype\ Foundry\ -\ Akkordeon\ Seven.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Akkordeon ten';

  src: url('./ten/Emtype\ Foundry\ -\ Akkordeon\ Ten.eot'); /* IE9*/
  src: url('./ten/Emtype\ Foundry\ -\ Akkordeon\ Ten.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./ten/Emtype\ Foundry\ -\ Akkordeon\ Ten.woff2')
      format('woff2'),
    /* chrome、firefox */ url('./ten/Emtype\ Foundry\ -\ Akkordeon\ Ten.woff')
      format('woff'),
    /* chrome、firefox */ url('./ten/Emtype\ Foundry\ -\ Akkordeon\ Ten.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./ten/Emtype\ Foundry\ -\ Akkordeon\ Ten.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
}

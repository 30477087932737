.RootTest {
  padding: $sectionPaddingSpace 0 $sectionPaddingSpace 0;
  z-index: 2;
  .rowSection {
    z-index: 3;
    max-width: 90%;
    @media (max-width: 991px) {
      max-width: 90%;
    }
  }
  @media (max-width: 991px) {
    padding: $sectionPaddingSpaceMobile 0;
  }
}

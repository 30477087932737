.RootSlider {
  padding: $sectionPaddingSpace 0 $sectionPaddingSpace 0;
  .rowSection {
    max-width: 90%;
    @media (max-width: 991px) {
      max-width: 90%;
    }
  }
  @media (max-width: 991px) {
    padding: $sectionPaddingSpaceMobile 0 0 0;
  }
}

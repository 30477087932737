/***********************************************************
* 
* This file imports all the necessary SASS & CSS stylesheets
* Please, do not edit this file
*
***********************************************************/

// Bootstrap Importing
@import '../node_modules/bootstrap/scss/bootstrap';

// AOS Library Importing
@import '../node_modules/aos/dist/aos.css';

// MAIN SCSS
@import './assets/sass/Main.scss';

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;700&display=swap');

// Cookies
@import './views/pages/cookies/cookies.scss';
// Privacity
@import './views/pages/privacity/Privacity.scss';
// Terms
@import './views/pages/terms/Terms.scss';

// MAINTENANCE
@import './Maintenance.scss';

.RootHome {
  position: relative;
  width: 100%;
  height: 100vh;
  // padding-top: 127px;

  @media (min-width: 992px) and (max-width: 1300px) and (max-height: 650px) {
    padding-top: 3vw;
  }

  .rowSection {
    z-index: 2;
  }

  .homeImgsWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      &:nth-child(1) {
        align-items: flex-start;
      }
      &:nth-child(2) {
        align-items: center;
      }
      &:nth-child(3) {
        align-items: flex-end;
      }

      img {
        opacity: 0.3;
      }

      .leftImg {
        width: 15vw;
        padding-top: 3vw;
      }
      .rightImg {
        width: 15vw;
        padding-bottom: 2vw;
      }
      .centerImg {
        // width: 40vw;
        height: 100%;
      }

      @media (max-width: 991px) {
        height: auto;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          align-items: center;
          width: 100%;
        }

        &:nth-child(1) {
          justify-content: flex-start;
        }
        &:nth-child(2) {
          justify-content: center;
        }
        &:nth-child(3) {
          justify-content: flex-end;
        }

        img {
          width: 100%;
        }

        .leftImg {
          width: 25vw;
          padding-top: 0;
        }
        .rightImg {
          width: 25vw;
          padding-bottom: 0;
        }
        .centerImg {
          // width: 40vw;
          height: auto;
          width: 40vw;
        }
      }
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      height: auto;
    }
  }

  @media (max-width: 991px) {
    height: auto;

    padding-top: 108px;
    padding-bottom: $sectionPaddingSpaceMobile;
  }
}

.rowSection {
  .tipsRow {
    .tipsCol {
      .numerationList {
        margin-bottom: 5vw;
        &:nth-child(1) {
          &::before {
            content: '1';
          }
        }
        &:nth-child(2) {
          &::before {
            content: '2';
          }
        }
        &:nth-child(3) {
          &::before {
            content: '3';
          }
        }
        &:nth-child(4) {
          &::before {
            content: '*';
          }
        }
        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        .tipsTitle {
          margin-bottom: 1vw;
          @media (max-width: 991px) {
            margin-bottom: 5vw;
          }
        }
      }
    }
    &.tipsRowButton {
      margin-top: 5vw;

      .tipsCol {
        display: flex;
        justify-content: center;
        align-items: center;

        & > div {
          text-align: center;
        }
      }
      @media (max-width: 991px) {
        margin-top: 10vw;
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media (max-width: 991px) {
      padding: 0 5vw;
    }
  }
}

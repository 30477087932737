.rowSection {
  .resultsRow {
    .resultsCol {
      position: relative;
      width: 50%;
      background: theme-color('bgColor');

      display: flex;
      justify-content: center;
      align-items: center;

      &.resultsColGrey {
        background: theme-color('cards');
        padding: 0;
      }

      &:before {
        content: '';
        float: left;
        padding-top: 90%; /* initial ratio of 1:1*/
      }

      .resultsDivWrap {
        p {
          margin-top: 1vw;
        }
        .borderLeftWrap {
          margin-top: 3vw;
          &:nth-child(1) {
            margin-top: 0;
            .borderTextContent {
              p {
                &:nth-child(1) {
                  margin-top: 0;
                }
              }
            }
          }
          @media (max-width: 991px) {
            margin-top: 8vw;
          }
        }
        .pTextImgWrap {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          flex-wrap: wrap;
          p {
            &:nth-child(1) {
              margin-bottom: 0;
            }
            span {
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
            }
          }

          img {
            width: 5vw;
            margin-left: 2vw;
            @media (max-width: 991px) {
              width: 13vw;
              margin-left: 5vw;
            }
          }
        }
      }
      &.resultsColTextContent {
        .resultsDivWrap {
          width: 80%;
        }
        @media (max-width: 991px) {
          padding: 7vw 3vw;
          .resultsDivWrap {
            width: 90%;
          }
        }
      }
      &.resultsColList {
        p {
          margin: 3vw 0;

          @media (max-width: 991px) {
            margin: 4vw 0 10vw 0;
          }
        }
      }
    }
    .resultsColBtn {
      margin-top: 4vw;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: none !important;
      }
      @media (max-width: 991px) {
        margin-top: 8vw;
      }
    }
  }
}

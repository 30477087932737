button,
a {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.buttonWrapper {
  transition: 0.3s;
  padding: 0 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
  &::before {
    transition: 0.3s linear;

    content: '';
    width: 100%;
    height: 100%;
    background: theme-color('primary');
    position: absolute;
    left: 0;
    top: 0;

    opacity: 0.5;
    transform: skewX(-25deg) scaleX(1);
  }
  background-position: right center;
  &:hover {
    // margin: 0 0.5em;
    &::before {
      transition: 0.5s linear;
      transform: skewX(-25deg) scaleX(0.5);
    }
    .buttonStyle {
      // animation: buttonHoverEffect 1s linear infinite alternate; // ANIMATION LOOP
      background-position: right center;
      margin: 0;
    }
  }

  .buttonStyle {
    display: block;
    padding: 1vw 3vw;
    text-decoration: none;
    text-align: center;
    border: none;
    color: theme-color('titleColor');

    position: relative;
    overflow: hidden;
    // background: theme-color('primary');
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

    background-image: theme-color('primaryButtons');
    background-size: 200% auto;
    text-transform: uppercase;
    transition: 0.5s;
    transform: skewX(-25deg);

    .buttonText {
      transform: skewX(25deg);
    }

    &.buttonStyleSecondary {
      background: theme-color('bgColor');
      border: 1px solid theme-color('secondary');
    }

    &.purchaseBtn {
      &:hover {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
      }
    }
    @media (max-width: 991px) {
      padding: 3vw 8vw;
    }
  }
  &.buttonStyleWrapPlay {
    padding: 0.5em;
    @media (max-width: 991px) {
      padding: 0.3em;
    }

    &:before {
      transform: skewX(0) scaleX(1);
      border-radius: 50%;
      transition: 0.3s linear;
    }
    &:hover {
      &:before {
        transition: 0.5s linear;

        transform: skewX(0) scale(0.5);
      }
    }
    .buttonStylePlay {
      border-radius: 50%;
      width: 5vw;
      height: 5vw;
      transform: skewX(0);
      padding: 0;

      // margin: 0.3vw;

      .buttonText {
        transform: skewX(0);
      }
      @media (max-width: 991px) {
        width: 15vw;
        height: 15vw;
      }
    }
    @media (max-width: 991px) {
      margin: 0;
    }
  }
  &.buttonWrapperSecondary {
    &::before {
      background: theme-color('textColor');
    }
    &:hover {
      .buttonStyleSecondary {
        background: theme-color('titleColor');
        color: theme-color('bgColor');
      }
    }
  }
}

.buttonsGroupWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}

@-webkit-keyframes buttonHoverEffect {
  0% {
    background-position: left center;
  }

  100% {
    background-position: right center;
  }
}
@-moz-keyframes buttonHoverEffect {
  0% {
    background-position: left center;
  }

  100% {
    background-position: right center;
  }
}
@keyframes buttonHoverEffect {
  0% {
    background-position: left center;
  }

  100% {
    background-position: right center;
  }
}

.rowSection {
  .platformRow {
    .platformCol {
      display: flex;
      justify-content: center;
      align-items: center;

      &:nth-child(1) {
        // padding-right: 10vw;
      }

      .platformTextWrap {
        z-index: 1;

        width: 100%;

        h3 {
          width: max-content;
          margin-bottom: 3vw;
          @media (max-width: 991px) {
            width: 100%;
          }
        }
        .borderLeftWrap {
          width: 80%;
          @media (max-width: 991px) {
            width: 100%;
          }
        }
      }

      .platformImgWrap {
        width: 100%;
        // height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .arrowDownTextWrap {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 15px;
          &:hover {
            .circleArrowDown {
              background: theme-color('secondary');
              svg {
                path {
                  stroke: theme-color('primary2');
                }
              }
            }
          }
          @media (max-width: 991px) {
            justify-content: center;
          }
        }
        @media (max-width: 991px) {
          height: auto;
        }
      }
      &.platformColImg {
        @media (max-width: 991px) {
          margin-top: 6vw;
          img {
            margin-bottom: 6vw;
          }
        }
      }
      .platformDropdownWrap {
        // padding-top: 2vw;
      }
      &.platformColInside {
        margin-top: 2vw;
        &:nth-child(1) {
          padding-left: 0;
        }
        &:nth-child(2) {
          padding-right: 0;
        }

        @media (max-width: 991px) {
          margin-top: 0;
          &:nth-child(1) {
            margin-top: 10vw;
            margin-bottom: 10vw;
          }
          &:nth-child(2) {
          }
        }
      }
    }
  }
}

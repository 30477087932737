.rowSection {
  .laptopImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0.5;
  }
  .registerRow {
    .registerCol {
      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .marginBetween {
          margin: 3vw 0;
        }
      }
      &.registerColTitle {
        & > div {
          p {
            margin-bottom: 2vw;
          }
        }
        h3 {
          color: transparent;
        }
        @media (max-width: 991px) {
          margin-top: 10vw;
        }
      }
      @media (max-width: 991px) {
        padding: 0 5vw;
      }
    }
  }
}

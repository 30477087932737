.rowSection {
  .planImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    opacity: 0.2;
  }
  .plansRow {
    .plansCol {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      & > div {
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        .marginBetween {
          margin: 3vw 0;
          @media (max-width: 991px) {
            margin: 10vw 0;
          }
        }

        .borderLeftWrap {
          text-align: left;
        }
      }
      &.plansColPadding {
        padding: 5vw 0;
      }
      &.plansColInfo {
        background: theme-color('cards');
      }
      &.plansColRemember {
        margin-top: 3vw;
        @media (max-width: 991px) {
          padding: 3vw 10vw 0 10vw;
        }
      }
      @media (max-width: 991px) {
        padding: 10vw 5vw;
        &.plansColPadding {
          padding: 10vw 10vw;
        }
      }
    }
  }
}

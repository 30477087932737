.rowSection {
  .earnRow {
    .earnCol {
      display: flex;
      justify-content: center;
      align-items: center;

      &.earnColTitle {
        justify-content: flex-start;
        .earnDivWrap {
          p {
            margin: 3vw 0;
            &:nth-child(1) {
              margin-top: 0;
            }
            &:nth-last-child(1) {
              margin-bottom: 0;
            }
            @media (max-width: 991px) {
              margin: 4vw 0 10vw 0;
            }
          }
        }
      }
      .earnDivWrap {
        position: relative;
        width: 90%;
        .pTextImgWrap {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 5vw;
            margin-left: 2vw;
            @media (max-width: 991px) {
              width: 13vw;
              margin-left: 5vw;
            }
          }
        }
        .earnCite {
          margin-top: 3vw;
          @media (max-width: 991px) {
            margin-top: 6vw;
          }
        }
        @media (max-width: 991px) {
          width: 100%;
        }
      }
      &.earnColTimeLine {
        justify-content: flex-end;
        .earnDivWrap {
          padding: 3vw 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }

        .earnCiteWrap {
          z-index: 1;
          margin-top: 3vw;
          p {
            text-align: center;
          }
          @media (max-width: 991px) {
            margin-top: 6vw;
          }
        }
      }
      .timeLineBackgroundColor {
        position: absolute;
        width: 80%;
        height: 100%;
        top: 0;
        background: theme-color('cards');
        @media (max-width: 991px) {
          width: 70%;
        }
      }
      .main-timeline {
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 2px;
          height: 100%;
          background: theme-color('secondary');
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
        .timeline {
          margin-bottom: 40px;
          position: relative;
          &:after {
            content: '';
            display: block;
            clear: both;
          }
          &:nth-child(2n) .timeline-content {
            float: left;
          }
          &:nth-child(2n) .timeline-content {
            padding: 20px 50px 20px 0;
            text-align: right;
          }
        }
        .icon {
          width: 18px;
          height: 18px;
          line-height: 18px;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          &:before {
            content: '';
            background: theme-color('primary');

            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.33s ease-out 0s;
          }
        }

        .date-content {
          width: 50%;
          float: left;
          margin-top: 22px;
          position: relative;
          &:before {
            content: '';
            width: 36.5%;
            height: 2px;
            background: #c6c6c6;
            margin: auto 0;
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
          }
        }
        .date-outer {
          width: 125px;
          height: 125px;
          font-size: 16px;
          text-align: center;
          margin: auto;
          z-index: 1;

          &:before,
          &:after {
            content: '';
            width: 125px;
            height: 125px;
            margin: 0 auto;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transition: all 0.33s ease-out 0s;
          }
          &:before {
            background: #fff;
            border: 2px solid #232323;
            left: -6px;
          }
          &:after {
            border: 2px solid #c6c6c6;
            left: 6px;
          }
        }

        .timeline-content {
          width: 50%;
          padding: 20px 0 20px 50px;
          float: right;
        }

        .description {
          margin-bottom: 0;
        }
      }

      @media only screen and (max-width: 991px) {
        .main-timeline .date-content {
          margin-top: 35px;
        }
        .main-timeline .date-content:before {
          width: 22.5%;
        }
        .main-timeline .timeline-content {
          padding: 10px 0 10px 30px;
        }
        .main-timeline .title {
          font-size: 17px;
        }
        .main-timeline .timeline:nth-child(2n) .timeline-content {
          padding: 10px 30px 10px 0;
        }
      }

      @media only screen and (max-width: 767px) {
        .main-timeline:before {
          margin: 0;
          left: 7px;
        }
        .main-timeline .timeline {
          margin-bottom: 20px;
          width: calc(100% - 30px);
          margin-left: auto;
        }
        .main-timeline .timeline:last-child {
          margin-bottom: 0;
        }
        .main-timeline .icon {
          margin: auto 0;
          left: -30px;
        }
        .main-timeline .date-content {
          width: 95%;
          float: right;
          margin-top: 0;
        }
        .main-timeline .date-content:before {
          display: none;
        }
        .main-timeline .date-outer {
          width: 110px;
          height: 110px;
        }
        .main-timeline .date-outer:before,
        .main-timeline .date-outer:after {
          width: 110px;
          height: 110px;
        }
        .main-timeline .date {
          top: 30%;
        }
        .main-timeline .year {
          font-size: 24px;
        }
        .main-timeline .timeline-content,
        .main-timeline .timeline:nth-child(2n) .timeline-content {
          width: 100%;
          text-align: center;
          padding: 10px 0;
          float: none;
        }
        .main-timeline .title {
          margin-bottom: 10px;
        }
        .timeLineBackgroundColor {
          margin-left: 30px;
        }
      }
    }
  }
}

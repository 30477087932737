// CUSTOM ARROWS
.slick-slider {
  .item {
    padding: 0 1vw;
    height: 100%;
    @media (max-width: 991px) {
      padding: 0 4vw;
    }
  }
}
.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;

  & > div {
    height: 100%;
  }
}
.customArrowsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1vw;
  .sliderArrowsCustom {
    background: transparent;
    border: 1px solid theme-color('titleColor');
    border-radius: 50%;

    width: 2.5vw;
    height: 2.5vw;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 1vw;
    transition: background-color 0.3s;

    svg {
      width: 1.5vw;
      height: 1.5vw;
      path {
        stroke: theme-color('titleColor');
        transition: stroke 0.3s;
      }
    }

    &:hover {
      background: theme-color('titleColor');
      path {
        stroke: theme-color('bgColor');
      }
    }
    @media (max-width: 991px) {
      padding: 0;
    }
  }
  @media (max-width: 991px) {
    margin-top: 3vw;
    .sliderArrowsCustom {
      width: 8.5vw;
      height: 8.5vw;
      margin: 0 4vw;

      svg {
        width: 4.5vw;
        height: 4.5vw;
      }
    }
  }
}

/*******************************
*
* Customize your CSS here
*
********************************/
#root {
  overflow: hidden;

  &.loaded {
    overflow: unset;
  }
}
body {
  background: theme-color('bgColor');
}
.row {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  @media (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
}

.borderLeftWrap {
  position: relative;
  // background: theme-color('primaryUp');
  // padding-left: 2px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 2px;
    background: theme-color('primaryUp');
  }

  .borderTextContent {
    color: white;
    padding-left: 2vw;

    * {
      margin-bottom: 0;
    }

    .borderTextMarginBottom {
      margin-bottom: 1em;
    }
    @media (max-width: 991px) {
      padding-left: 4vw;
    }
  }

  &.numerationList {
    &::before {
      position: absolute;
      left: -3vw;
      top: 0;
      color: #fff;
    }
    @media (max-width: 991px) {
      &::before {
        left: -7vw;
      }
    }
  }
}

.customList {
  list-style: none;
  padding-left: 0;

  // width: 60%;
  li {
    @extend p;
    text-align: center;
    // align-items: center;

    &::before {
      content: '';
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      width: 7vw;
      height: 7vw;
      max-width: 15px;
      max-height: 15px;
      margin-right: 1em;
      border-radius: 50%;
      background: theme-color('primary');
    }
    @media (max-width: 991px) {
      position: relative;
      text-align: left;
      padding-left: 6vw;

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 3vw;
        height: 3vw;
      }
    }
  }
}
.customBullet {
  display: flex;
  text-align: left;
  align-items: center;
  &::before {
    content: '';
    font-weight: bold;
    display: inline-block;
    width: 1.5vw;
    height: 1.5vw;
    max-width: 30px;
    max-height: 30px;
    margin-right: 0.5vw;
    background-image: url('../../imgs/icons/customListBullet.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media (max-width: 991px) {
    &::before {
      width: 3.5vw;
      height: 3.5vw;
      margin-right: 2.5vw;
    }
  }
}

.circleArrowDown {
  position: relative;
  border: 1px solid theme-color('secondary');
  border-radius: 50%;

  width: 2.5vw;
  height: 2.5vw;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 1.5vw;

  cursor: pointer;

  svg {
    position: absolute;
    width: 50%;
    height: 50%;
    path {
      transition: stroke 0.3s;
    }
  }
  transition: background-color 0.3s;

  @media (max-width: 991px) {
    width: 8.5vw;
    height: 8.5vw;
  }
}

// PLAY
.playContainer {
  position: absolute;
  width: 100%;
  height: calc(100% - 40px);
  top: 0;
  left: 0;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity 0.3s;
  .buttonStyleWrapPlay {
    margin-top: 40px;
  }
  &:hover {
    .buttonStyleWrapPlay {
      &:before {
        transition: 0.5s linear;

        transform: skewX(0) scale(0.5);
      }
      .buttonStyle {
        background-position: right center;
        margin: 0;
      }
    }
  }
  &.playingVideo {
    opacity: 0;
  }
  @media (max-width: 991px) {
    height: calc(100% - 40px);
    .buttonStyleWrapPlay {
      margin-top: 40px !important;
    }
  }
}

.rowSection {
  .testRow {
    .testCol {
      &.testColTitle {
        text-align: center;
      }
      &.testColSlider {
        margin-top: 3vw;
        .slides-container {
          .slide {
            picture {
              position: relative;
              width: 100%;
              height: 100%;
            }
            &.testImgWrapInvert {
              picture {
                &::before {
                  content: 'DESPUÉS';
                }
                &::after {
                  content: 'ANTES';
                  right: 50%;
                  transform: translateX(100%);
                }
              }
            }
            width: calc(30%);
            overflow: hidden;
            // padding-left: 2em;
            // padding-right: 2em;
            height: 100%;
            padding: 0 2vw;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            @media (max-width: 991px) {
              width: calc(100%);
              padding: 0 6vw;
              img {
                // padding-bottom: 8vw;
              }
            }
          }
        }
        @media (max-width: 991px) {
          margin-top: 6vw;
        }
      }
    }
  }
}

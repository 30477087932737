.rowSection {
  .insideRow {
    .insdeCol {
      &.insdeColTitle {
        display: flex;
        justify-content: center;
        align-items: center;

        .insideTitleWrap {
          text-align: center;

          p {
            justify-content: center;

            margin: 1.5vw 0 3vw 0;
            @media (max-width: 991px) {
              margin: 6.5vw 0 12vw 0;
            }
          }
        }
      }
      &.insdeColCite {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 3vw;
        @media (max-width: 991px) {
          margin-top: 6vw;
        }
      }

      .cardStyle {
        height: 100%;
        padding: 1vw;
        .cardBody {
          h4 {
            margin-bottom: 3vw;
            @media (max-width: 991px) {
              margin-bottom: 6vw;
            }
          }
        }
        @media (max-width: 991px) {
          padding: 3vw;
        }
      }
    }
  }
}

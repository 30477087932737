// FONTS
// Akkordeon
@import '../../fonts/Akkordeon/style.scss';

// ROBOTO
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');
//
a[href^='http'] {
  color: theme-color('primary2');
}
h1 {
  font-family: 'Akkordeon fourteen';
  font-style: normal;
  font-weight: 900;
  font-size: 9vw;
  line-height: 7.5vw;
  color: theme-color('titleColor');

  &.h1Smaller {
    font-size: 4.5vw;
    line-height: 5vw;
    @media (max-width: 991px) {
      font-size: 10.5vw;
      line-height: 11vw;
      text-align: center;
    }
  }

  @media (max-width: 991px) {
    font-size: 20vw;
    line-height: 15.5vw;
  }
}
h2 {
  font-family: 'Akkordeon seven';
  font-style: normal;
  font-weight: 550;
  font-size: 5.5vw;
  line-height: 5.5vw;
  color: transparent;
  -webkit-text-stroke: 1px theme-color('titleColor');

  @media (max-width: 991px) {
    font-size: 12vw;
    line-height: 8vw;
    -webkit-text-stroke: 0.3px theme-color('titleColor');
  }
}

h3 {
  font-family: 'Akkordeon ten';
  font-style: normal;
  font-weight: bold;
  font-size: 4.5vw;
  line-height: 4.5vw;
  letter-spacing: 0.01em;

  color: theme-color('titleColor');

  text-transform: uppercase;

  &.h3SmallerTitle {
    font-family: 'Akkordeon ten';
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 55px;

    letter-spacing: 0.03em;

    color: theme-color('titleColor');

    &.strokeBorder {
      -webkit-text-stroke: 1px theme-color('titleColor');
      color: transparent;
      @media (max-width: 991px) {
        -webkit-text-stroke: 0.3px theme-color('titleColor');
      }
    }
    @media (max-width: 1600px) {
      font-size: 4vw;
      line-height: 3.5vw;
    }
    @media (max-width: 991px) {
      font-size: 12vw;
      line-height: 11vw;
    }
  }
  @media (max-width: 991px) {
    font-size: 9vw;
    line-height: 9vw;
  }
}

h4 {
  font-family: 'Akkordeon seven';
  font-size: 4.5vw;
  line-height: 3.5vw;

  color: theme-color('titleColor');

  span {
    font-size: 2vw;
    line-height: 1.5vw;
    vertical-align: super;
    text-transform: uppercase;
  }
  @media (max-width: 1300px) {
    font-size: 6vw;
    line-height: 5vw;
  }
  @media (max-width: 991px) {
    font-size: 8.5vw;
    line-height: 7.5vw;
    span {
      font-size: 6vw;
      line-height: 5.5vw;
    }
    &.h4HeroPrice {
      font-size: 13.5vw;
      line-height: 12.5vw;
    }
    &.strokeBorder {
      -webkit-text-stroke: 0.3px theme-color('titleColor');
      color: transparent;
    }
  }
}

h5 {
  font-family: 'Akkordeon five';
  font-size: 1.8vw;
  line-height: 1.8vw;

  color: theme-color('titleColor');
  letter-spacing: 0.03em;
  &.tipsTitle {
    font-size: 3vw;
    line-height: 2.5vw;
  }
  @media (max-width: 1300px) {
    font-size: 2.3vw;
    line-height: 2.3vw;
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    font-size: 6.8vw;
    line-height: 6.8vw;
    margin-bottom: 0;
    &.tipsTitle {
      font-size: 8vw;
      line-height: 7.5vw;
    }
  }
}

h6 {
  &.faqTitle {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 46px;

    color: theme-color('titleColor');

    @media (max-width: 991px) {
      font-size: 5vw;
      line-height: 5.5vw;
    }
  }
}

p {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: normal;
  font-size: 1.5vw;
  line-height: 1.8vw;

  color: theme-color('textColor');

  &.pOpac {
    color: theme-color('titleColor');
  }

  &.pTextSmaller,
  .pTextSmaller {
    font-size: 1.1vw;
    line-height: 1.4vw;
  }
  @media (max-width: 1300px) {
    font-size: 2vw;
    line-height: 2.3vw;
    &.pTextSmaller,
    .pTextSmaller {
      font-size: 1.7vw;
      line-height: 2vw;
    }
  }
  @media (max-width: 991px) {
    font-size: 4.5vw;
    line-height: 5vw;
    &.pTextSmaller {
      font-size: 4.3vw;
      line-height: 5vw;
    }
  }
}

.buttonText {
  font-family: 'Akkordeon nine';
  font-style: normal;
  font-weight: 650;
  font-size: 1.1vw;
  line-height: 1.5vw;
  letter-spacing: 0.25em;

  text-transform: uppercase;
  @media (max-width: 1300px) {
    font-size: 1.6vw;
    line-height: 2vw;
  }
  @media (max-width: 991px) {
    font-size: 5.1vw;
    line-height: 5.5vw;
  }
}

.subPriceText {
  font-family: 'Akkordeon five';
  font-size: 2.5vw;
  line-height: 2.5vw;

  color: theme-color('titleColor');

  margin-bottom: 0;

  text-align: center;
  @media (max-width: 991px) {
    font-size: 7.5vw;
    line-height: 7.5vw;
  }
}
.priceText {
  position: relative;
  font-family: 'Akkordeon seven';
  font-size: 9vw;
  line-height: 6vw;

  color: theme-color('titleColor');

  text-align: center;
  margin-bottom: 0;
  margin-top: 1vw;

  animation: heartbeat 4s infinite;

  span {
    font-size: 4vw;
    line-height: 3vw;
    vertical-align: super;
    text-transform: uppercase;
    position: absolute;
    top: 0;
  }
  @media (max-width: 991px) {
    font-size: 25vw;
    line-height: 20vw;
    span {
      font-size: 10vw;
      line-height: 10vw;
    }
  }
}

.titleColor {
  color: theme-color('titleColor');
}
.textColor {
  color: theme-color('textColor');
}

.boldText {
  font-weight: bold;
}

.numerationList {
  &::before {
    font-family: 'Akkordeon seven';
    font-style: normal;
    font-weight: 550;
    font-size: 7vw;
    line-height: 5vw;
    @media (max-width: 991px) {
      font-size: 12vw;
      line-height: 10vw;
    }
  }
}

.footerTitle {
  font-family: 'Akkordeon five';
  font-style: normal;
  font-weight: 450;
  font-size: 1.5vw;
  line-height: 2vw;

  text-transform: uppercase;

  color: theme-color('titleColor');
  @media (max-width: 991px) {
    font-size: 6.5vw;
    line-height: 7vw;
  }
}

.footerText {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: normal;
  font-size: 1vw;
  line-height: 1vw;
  @media (max-width: 991px) {
    font-size: 4vw;
    line-height: 4vw;
  }
}

.powered {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: normal;
  font-size: 0.8vw;
  line-height: 0.8vw;
  letter-spacing: 0.2em;

  @media (max-width: 991px) {
    font-size: 4vw;
    line-height: 4vw;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.8);
  }

  5% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.8);
  }

  15% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(0.8);
  }
}
